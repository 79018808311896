import * as React from 'react';
import { graphql, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { FaRocket } from '@react-icons/all-files/fa/FaRocket';


const SocialLinks = ({ data, location }) => {
    const {siteTitle, author, social} = data.site.siteMetadata;

  return (
    <Layout location={location} title={siteTitle} hideFooter={true}>
        <div className="social-links">
            <div className="social-links-banner">
                <StaticImage
                    className="social-links-avatar"
                    layout="fixed"
                    formats={['auto', 'webp', 'avif']}
                    src="../images/profile-pic.jpg"
                    width={100}
                    height={100}
                    alt="Profile picture"
                />
                <h2 className="name">{author.name}</h2>
                <p className="location">{author.location}</p>
                <p className="subtitle">"{author.subtitle}"</p>
            </div>
            <a className="social-links-button special" href={`https://tringacodes.com/simple-ways-to-improve-your-site-make-it-more-accessible`}>
                <FaRocket style={{"font-size":"16px"}} /> Latest blog post
            </a>
            <Link className="social-links-button" to="/">
                My website
            </Link>
            <a className="social-links-button" href={`${social?.hashnode || ``}`}>
                Hashnode blog
            </a>
            <a className="social-links-button" href={`${social?.instagram || ``}`}>
                Instagram
            </a>
            <a className="social-links-button" href={`${social?.linkedIn || ``}`} >
                LinkedIn
            </a>
            <a className="social-links-button" href={`${social?.github || ``}`}>
                Github
            </a>
            <a className="social-links-button" href={`${social?.frontendMentor || ``}`}>
                FrontEnd Mentor
            </a>
        </div>
    </Layout>
  );
};

export const Head = () => <Seo title="Social links" />;

export default SocialLinks;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        author {
            name
            location
            subtitle
        }
        siteUrl
        social {
            instagram
            linkedIn
            github
            frontendMentor
            hashnode
        }
      }
    }
  }
`;
